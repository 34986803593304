






































































import {
  computed, ComputedRef,
  defineComponent, PropType, toRefs,
} from '@nuxtjs/composition-api';
import type { Product } from '~/modules/catalog/product/types';
import { SimpleProduct } from '~/modules/GraphQL/types';
import useUser from '~/modules/customer/composables/useUser';
import {
  getPriceForLoggedCustomer, getPriceThreshold, isLoggedCustomerGroupPrice, isTableForCustomerGroupPrice,
} from '~/modules/catalog/product/helpers/customerGroupPriceHelpers';
import { calculateDiscountPercentage } from '~/modules/catalog/product/helpers/calculateDiscountPercentage';

export default defineComponent({
  name: 'ProductTierRatesTable',
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    currentPrice: {
      type: [Number, String] as PropType<number | string>,
      default: null,
    },
  },
  setup(props) {
    const {
      product, currentPrice,
    } = toRefs(props);
    const { isAuthenticated } = useUser();

    const currentProduct: ComputedRef<SimpleProduct> = computed(
      () => product.value?.configurable_product_options_selection_all?.variant ?? product.value,
    );
    const priceForLoggedCustomer = computed(() => getPriceForLoggedCustomer(currentProduct.value));
    const priceThreshold = computed(() => getPriceThreshold(currentProduct.value));
    const tableForCustomerGroupPrice = computed(() => isTableForCustomerGroupPrice(currentProduct.value));
    const loggedCustomerGroupPrice = computed(() => isLoggedCustomerGroupPrice(currentProduct.value));

    const getPercentage = (discountedPrice:number | string) => calculateDiscountPercentage({ discountedPrice, originalPrice: currentPrice.value });

    return {
      isAuthenticated,
      priceThreshold,
      priceForLoggedCustomer,
      tableForCustomerGroupPrice,
      loggedCustomerGroupPrice,
      getPercentage,
    };
  },
});
